<template>
    <div class="home">
        <header :class="`header ${topNavClass}`">
            <div class="center-wrapper">
                <router-link to="/home" class="logo"><img src="../assets/img/nav_logo.png" alt=""></router-link>
                <nav class="nav">
                    <div
                    :class="idx === activeNav ? 'active' : ''"
                    v-for="(nav, idx) in navs"
                    :key="idx"
                    @click="navClickHandle(idx, nav)"
                    >
                    {{ nav.name }}
                    </div>
                </nav>
            </div>
        </header>
        <el-carousel trigger="click" height="100vh" indicator-position="none">
            <el-carousel-item v-for="(item,idx) in imgs" :key="idx">
                <img :src="item.img" alt="">
                <router-link :to="item.path" class="more-btn"><p>了解更多</p></router-link>
            </el-carousel-item>
        </el-carousel>
        <footer class="footer"><a href="http://beian.miit.gov.cn" target="_blank">版权所有©️京ICP备20006428号-2</a></footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            topNavClass: 'fixed-nav',
            activeNav: -1,
            navs: [
                { name: "网络货运监管服务平台", path: "/mag", bg: "one" },
                { name: "网络货运平台", path: "/net", bg: "two" },
                { name: "厂矿无人值守管理系统", path: "/bobody", bg: "three" },
                { name: "关于我们", path: "/about", bg: "four"}
            ],
            imgs: [
                {img: require('../assets/img/home_img2.png'), path: '/mag'},
                {img: require('../assets/img/home_img1.png'), path: '/net'},
                {img: require('../assets/img/home_img3.png'), path: '/bobody'}
            ]
        }
    },
    methods:{
        navClickHandle(idx, nav) {
            this.$router.push({ path: nav.path });
        },
    }
}
</script>
<style lang="scss" scoped>
.header {
    height: 80px;
    .center-wrapper {
    display: flex;
    justify-content: space-between;
    }
    &.sticky-nav {
    position: sticky;
    top: 0;
    background: #00000050;
    }
    &.fixed-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin:auto;
    z-index:99;
    }
    .logo {
    /* width: 174px;
    height: 37px; */
    margin-top: 21px;
    }
    .nav {
    margin-top: 30px;
    display: flex;
    font-size: 16px;
    color: #fff;
    height: 25px;
    justify-content: space-between;
    flex: 1;
    margin-left: 200px;
    div {
        cursor: pointer;
        &.active {
        border-bottom: 2px solid #fff;
        }
    }
    }
}
.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin:auto;
    z-index:99;
    text-align: center;
    color: #FFF;
    height: 30px;
    line-height:30px;
    background: #92bcf604;
    opacity: 0.4;
    a {
        color: #FFF;
    }
}
.el-carousel__item {
    img {
        width: 100vw;
        height: 100vh;
    }
    .more-btn{
        width: 190px;
        height: 60px;
        background: #F39713;
        border-radius: 4px;
        position: absolute;
        bottom: 35vh;
        left: 20vw;
        p {
            text-align: center;
            font-size: 22px;
            height: 60px;
            line-height: 60px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #FFFFFF;
        }
    }
}

</style>